import React from 'react'
import PropTypes from 'prop-types'

const Container = props => {
  return (
    <div className={`container ${props.className}`}>
      { props.children }
    </div>
  )
}

Container.propTypes = {
  className: PropTypes.string
}

Container.defaultProps = {
  className: ''
}

export default Container
