import React from 'react'
import Content from 'components/Layout/Content'

const Boxes = () => {
  return (
    <Content className='boxes'>
      <img src='/images/box-frete-gratis.jpg' alt='Frete grátis para SP!' />
      <img src='/images/box-formas-pagamento.jpg' alt='Formas de pagamento exclusivas' />
    </Content>
  )
}

export default Boxes
