import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const MenuTop = props => {
  const currentPage = page => (
    page === props.page ? 'active' : ''
  )

  return (
    <nav className='header-menu only-desktop'>
      <ul>
        <li className={currentPage('home')}>
          <Link to='/'>HOME</Link>
        </li>
        <li className={currentPage('produtos')}>
          <Link to='/produtos/chipboard-chata'>PRODUTOS</Link>
        </li>
        <li className={currentPage('quem-somos')}>
          <Link to='/quem-somos'>QUEM SOMOS</Link>
        </li>
        <li className={currentPage('contato')}>
          <Link to='/localizacao'>LOCALIZAÇÃO</Link>
        </li>
      </ul>
    </nav>
  )
}

MenuTop.propTypes = {
  page: PropTypes.string.isRequired
}

export default MenuTop
