import React from 'react'
import Container from 'components/Layout/Container'
import Content from 'components/Layout/Content'

const Strip = () => {
  return (
    <Container className='strip-products'>
      <Content className='strip-products-content'>
        <img src='/images/dobradicas.png' alt='dobradiças' />
        <div>
          <h2 className='strip-title text-border-blue'>
            PRODUTOS DE FIXAÇÃO E ACESSÓRIOS PARA MÓVEIS
          </h2>
          <p className='strip-description text-border-blue'>
            Produtos certificados e fabricados com material de alta qualidade
          </p>
        </div>
      </Content>
    </Container>
  )
}

export default Strip
