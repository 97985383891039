import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from './App'

import HomePage from 'pages/Home/HomePage'
import ProductsPage from 'pages/Produtos/ProductsPage'
import QuemSomosPage from 'pages/QuemSomos/QuemSomosPage'
import ContatoPage from 'pages/Contato/ContatoPage'

const Routes = () => {
  return (
    <Router>
      <App>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/produtos/:product' component={ProductsPage} />
          <Route exact path='/quem-somos' component={QuemSomosPage} />
          <Route exact path='/localizacao' component={ContatoPage} />
        </Switch>
      </App>
    </Router>
  )
}

export default Routes
