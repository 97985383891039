import React, { Component, Fragment } from 'react'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import Container from 'components/Layout/Container'
import ProductsMenu, { AllMenuItems } from './components/ProductsMenu'
import Slider from 'components/Slider/Slider'

class ProductsPage extends Component {

  renderProduct = () => {
    const product = this.props.match.params.product
    const item = AllMenuItems.find(item => item.link === product)
    const Component = item.component
    return (<Component renderSlider={this.renderSlider} renderTable={this.renderTable} />)
  }

  renderImage = (image, alt) => (
    <img
      key={`product-${image}`}
      className='product-info-image'
      src={image}
      alt={alt} />
  )

  renderSlider = (images, alt) => {
    return (
      <Slider
        className='product-slide'
        settings={{initialSlideHeight: 780, heightMode: 'max'}}>
        {images.map(image => this.renderImage(image, alt))}
      </Slider>
    )
  }

  renderHead = (column, index) => (
    <th key={`column-${index}`}>{column}</th>
  )

  renderBody = (line, index) => (
    <tr key={`line-${index}`}>
      {
        line.map((item, index) => {
          if (index === 0) {
            return (<th key={`item-column-${index}`}>{item}</th>)
          }

          return (<td key={`item-column-${index}`}>{item}</td>)
        })
      }
    </tr>
  )

  renderTable = (head, body) => {
    return (
      <table className='table'>
        <thead>
        <tr>
          {head.map((column, index) => this.renderHead(column, index))}
        </tr>
        </thead>
        <tbody>
          {body.map((line, index) => this.renderBody(line, index))}
        </tbody>
      </table>
    )
  }

  render () {
    return (
      <Fragment>
        <Header page='produtos' />
        <main className='products'>
          <Container className='products-banner text-border-blue'>
            PRODUTOS PARA FIXAÇÃO E ACESSÓRIOS PARA MÓVEIS
          </Container>

          <div className='products-content'>
            <div className='products-menu only-desktop'>
              <div className='products-menu-header'>
                PRODUTOS
              </div>
              <ProductsMenu product={this.props.match.params.product} />
            </div>

            <div className='product-info'>
              {this.renderProduct()}
            </div>
          </div>
        </main>
        <Footer />
      </Fragment>
    )
  }
}

export default ProductsPage
