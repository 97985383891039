import React, { Component, Fragment } from 'react'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import Content from 'components/Layout/Content'
import { Link } from 'react-router-dom'

const products = [
  { img: '/images/produtos/highlights/corredica.png', label: 'Corrediça', link: '/produtos/corredica-larga' },
  { img: '/images/produtos/highlights/dobradica-super-curva.png', label: 'Dobradiça super curva', link: '/produtos/dobradica-super-curva' },
  { img: '/images/produtos/highlights/GN25.png', label: 'Trombeta GN25', link: '/produtos/gn25' },
  { img: '/images/produtos/highlights/chipboard.png', label: 'Chipboard' , link: '/produtos/chipboard'},
  { img: '/images/produtos/highlights/panela.png', label: 'Parafuso Panela', link: '/produtos/panela' },
  { img: '/images/produtos/highlights/flangeado.png', label: 'Flangeado', link: '/produtos/flangeado-broca' },
]

class QuemSomosPage extends Component {
  renderProduct = product => (
    <div key={`product-${product.label}`}>
      <Link to={product.link}>
        <img src={product.img} alt={product.label} />
      </Link>
    </div>
  )

  render () {
    return (
      <Fragment>
        <Header page='quem-somos' />
        <main className='quem-somos'>
          <div className='quem-somos-banner'>
            <img src='/images/quem-somos/gtr-banner-logo.png' alt='GTR Fixação Logo' className='banner-logo' />
            <div className='text-border-white text-banner'>
              VENDA DE PRODUTOS DE FIXAÇÃO E ACESSÓRIOS PARA MÓVEIS
            </div>
          </div>

          <Content className='quem-somos-text'>
            <h2 className='title'>Sobre a GTR Fixação</h2>
            <p>
              A GTR Fixações atua no seguimento de Produtos de Fixação e Acessórios para Móveis desde 2011, visando
              atender nossos clientes com máxima eficiência e qualidade, trazendo ótima solução em termos de fixação.
            </p>
            <p>
              Nossa linha de produtos é de origem totalmente importada, seguimos um padrão de qualidade reconhecido por
              todos que conhecem nosso trabalho.
            </p>
            <p>
              Conheça você também nosso trabalho! Produtos de qualidade e com ótimas condições é GTR Fixações.
            </p>
          </Content>

          <Content className='quem-somos-info'>
            <div>
              <h4 className='title'>Endereço</h4>
              <address>
                Av. Patos, 420 – Cidade Industrial Satélite<br/>
                Guarulhos / SP – CEP 07222-010
              </address>
            </div>
            <div>
              <h4 className='title'>Atendimento</h4>
              De segunda à sexta-feira:<br/>
              Das 08:00 as 17:00
            </div>
          </Content>

          <Content className='quem-somos-products'>
            <h2 className='title'>ALGUNS DE NOSSOS PRODUTOS</h2>
            <div className='quem-somos-products-list'>
              {products.map(this.renderProduct)}
            </div>
          </Content>
        </main>
        <Footer />
      </Fragment>
    )
  }
}

export default QuemSomosPage