import React from 'react'
import Container from 'components/Layout/Container'
import Content from 'components/Layout/Content'

const FooterRights = () => {
  return (
    <Container className='footer-rights'>
      <Content className='footer-rights-content'>
        <div>
          2019 - Todos os direitos reservados
        </div>
        <div>
          Desenvolvido por 
          <a
            href='http://codemind.com.br/'
            target='_blank'
            rel='noopener noreferrer'
            > Codemind</a>
        </div>
      </Content>
    </Container>
  )
}

export default FooterRights
