import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Chipboard from './products/Chipboard'
import ChipboardFlangeada from './products/ChipboardFlangeada'
import CorredicaLarga from './products/CorredicaLarga'
import CorredicaLight from './products/CorredicaLight'
import CorredicaComAmortecedor from './products/CorredicaComAmortecedor'
import DobradicaCurva from './products/DobradicaCurva'
import DobradicaClickCurva from './products/DobradicaClickCurva'
import DobradicaClickReta from './products/DobradicaClickReta'
import DobradicaCurvaAmortecedor from './products/DobradicaCurvaAmortecedor'
import DobradicaReta from './products/DobradicaReta'
import DobradicaRetaAmortecedor from './products/DobradicaRetaAmortecedor'
import DobradicaSuperCurva from './products/DobradicaSuperCurva'
import FlangeadoAgulha from './products/FlangeadoAgulha'
import FlangeadoBroca from './products/FlangeadoBroca'
import FlangeadoBrocaSerrilha from './products/FlangeadoBrocaSerrilha'
import TrombetaGN25 from './products/TrombetaGN25'
import Panela from './products/Panela'
import RodizioComFreio from './products/RodizioComFreio'
import RodizioSemFreio from './products/RodizioSemFreio'

const Chipboards = [
  { link: 'chipboard-chata', label: 'Chata', items: [], component: Chipboard },
  { link: 'chipboard-flangeada', label: 'Flangeada', items: [], component: ChipboardFlangeada },
]

const Corredicas = [
  { link: 'corredica-larga', label: 'Larga', items: [], component: CorredicaLarga },
  { link: 'corredica-com-amortecedor', label: 'Larga Amortecedor', items: [], component: CorredicaComAmortecedor },
  { link: 'corredica-light', label: 'Light', items: [], component: CorredicaLight },
]

const Dobradicas = [
  { link: 'dobradica-curva', label: 'Curva', items: [], component: DobradicaCurva },
  { link: 'dobradica-curva-amortecedor', label: 'Curva Amortecedor', items: [], component: DobradicaCurvaAmortecedor },
  { link: 'dobradica-reta', label: 'Reta', items: [], component: DobradicaReta },
  { link: 'dobradica-reta-amortecedor', label: 'Reta Amortecedor', items: [], component: DobradicaRetaAmortecedor },
  { link: 'dobradica-super-curva', label: 'Super Curva', items: [], component: DobradicaSuperCurva },
  { link: 'dobradica-click-curva', label: 'CLICK Curva', items: [], component: DobradicaClickCurva },
  { link: 'dobradica-click-reta', label: 'CLICK Reta', items: [], component: DobradicaClickReta }
]

const Flangeado = [
  { link: 'flangeado-agulha', label: 'Agulha', items: [], component: FlangeadoAgulha },
  { link: 'flangeado-broca', label: 'Broca', items: [], component: FlangeadoBroca },
  { link: 'flangeado-broca-serrilha', label: 'Broca Serrilha', items: [], component: FlangeadoBrocaSerrilha }
]

const Rodizios = [
  { link: 'rodizio-com-freio', label: 'Com Freio', items: [], component: RodizioComFreio },
  { link: 'rodizio-sem-freio', label: 'Sem Freio', items: [], component: RodizioSemFreio },
]

const ProductMenu = [
  { link: 'chipboard-chata', label: 'Parafuso Chipboard', items: Chipboards },
  { link: 'flangeado-agulha', label: 'Parafuso Flangeado', items: Flangeado },
  { link: 'gn25', label: 'Parafuso GN25', items: [], component: TrombetaGN25 },
  { link: 'panela', label: 'Parafuso Panela', items: [], component: Panela },
  { link: 'corredica-larga', label: 'Corrediças', items: Corredicas },
  { link: 'rodizio-com-freio', label: 'Rodízios', items: Rodizios },
  { link: 'dobradica-curva', label: 'Dobradiças', items: Dobradicas },
]

export const AllMenuItems = Corredicas
  .concat(Chipboards)
  .concat(Rodizios)
  .concat(Dobradicas)
  .concat(Flangeado)
  .concat(ProductMenu)

class ProductsMenu extends Component {

  closeMenu = () => {
    if (!this.props.toCloseMenu) {
      return
    }

    const button = document.body.querySelector('div.bm-cross-button button')
    window.scrollTo( 0, 0);
    button.click()
  }

  renderMenuItem = item => {
    const active = this.props.product === item.link && item.items.length === 0 ? 'active' : ''
    return (
      <li key={`product-${item.label}`} className={`product-menu-item ${active}`}>
        <Link to={`/produtos/${item.link}`} alt={item.label} onClick={this.closeMenu}>
          {item.label}
        </Link>
        {item.items.length > 0 && this.renderSubMenuItem(item.items)}
      </li>
    )
  }

  renderSubMenuItem = items => (
    <ul className='product-submenu'>
      {items.map(this.renderMenuItem)}
    </ul>
  )
  
  render () {
    return (
      <nav className='products-menu-nav'>
        <ul className='product-menu'>
          {ProductMenu.map(this.renderMenuItem)}
        </ul>
      </nav>
    )
  }

  static propTypes = {
    toCloseMenu: PropTypes.bool,
    product: PropTypes.string.isRequired
  }
}

export default ProductsMenu
