import React from 'react'

const Address = () => {
  return (
    <address>
      Av. Patos, 420<br/>
      Cidade Industrial Satélite<br/>
      Guarulhos - São Paulo<br/>
      CEP 07222-010
    </address>
  )
}

export default Address
