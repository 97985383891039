import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import ProductsMenu from 'pages/Produtos/components/ProductsMenu'

const MenuSlide = props => {

  const currentPage = page => {
    return page === props.page ? 'active' : ''
  }

  const productItem = window.location.pathname.split('/').pop()

  return (
    <Menu isOpen={false}>
      <Link to='/' className={currentPage('home')}>
        Home
      </Link>
      <Link to='/quem-somos' className={currentPage('quem-somos')}>
        Quem Somos
      </Link>
      <Link to='/localizacao' className={currentPage('contato')}>
        Localização
      </Link>
      <Link to='/produtos/chipboard' className={currentPage('produtos')}>
        Produtos
      </Link>

      <div className='products-slide-menu'>
        <ProductsMenu product={productItem} toCloseMenu />
      </div>
    </Menu>
  )
}

MenuSlide.propTypes = {
  page: PropTypes.string.isRequired
}

export default MenuSlide
