import React, { Fragment } from 'react'

const CorredicaLight = props => {

  const url = '/images/produtos/corredica-light'
  const images = [
    `${url}/corredica-light-01.jpg`,
    `${url}/corredica-light-02.jpg`,
    `${url}/corredica-light-03.jpg`
  ]

  const alt = 'Corrediça Larga'

  return (
    <Fragment>
      <div className='product-text'>
        {props.renderSlider(images, alt)}
        <h2>Corrediça Telescópica Light - 35mm</h2>
        <p>
          As corrediças telescópicas GTR Parafusos, são Importadas em aço zincado,
          cobertura protetora contra oxidação, com sistema de fixação lateral,
          possui trava que permite a retirada completa da gaveta sempre que necessário. 
        </p>
        <p>
          <strong>
            *Corrediça telescópica light - 35mm de largura, suporta gavetas de até 35 kg
          </strong>
        </p>
        <h3>Tamanhos:</h3>
        TRILHO TELESCOPICO LIGHT 250 MM<br/>
        TRILHO TELESCOPICO LIGHT 300 MM<br/>
        TRILHO TELESCOPICO LIGHT 350 MM<br/>
        TRILHO TELESCOPICO LIGHT 400 MM<br/>
        TRILHO TELESCOPICO LIGHT 450 MM<br/>
        TRILHO TELESCOPICO LIGHT 500 MM<br/>
        TRILHO TELESCOPICO LIGHT 550 MM<br/>
        TRILHO TELESCOPICO LIGHT 600 MM<br/>
      </div>
    </Fragment>
  )
}

export default CorredicaLight
