import React from 'react'
import Carousel from 'nuka-carousel'

const Slider = props => {

  const slidesQtt = props.children.length
  const hide = slidesQtt > 1 ? '' : 'hide'

  const settings = {
    autoplay: slidesQtt > 1,
    autoplayInterval: 5000,
    pauseOnHover: true,
    wrapAround: slidesQtt > 1,
    slidesToScroll: slidesQtt === 1 ? 0 : 1
  }

  return (
    <div className={`slider ${props.className}`}>
      <Carousel
        {...props.settings}
        {...settings}
        renderCenterLeftControls={({ previousSlide }) => (
          <span onClick={previousSlide} className={`slider-previous ${hide}`}>
            <img src='/images/arrow-left.png' alt='Voltar' />
          </span>
        )}

        renderCenterRightControls={({ nextSlide }) => (
          <span onClick={nextSlide} className={`slider-next ${hide}`}>
            <img src='/images/arrow-right.png' alt='Avançar' />
          </span>
        )}
      >
        {props.children}
      </Carousel>
    </div>
  )
}

export default Slider
