import React, { Fragment } from 'react'

const Panela = props => {
  const url = '/images/produtos/panela'
  const images = [
    `${url}/panela-01.jpg`,
    `${url}/panela-02.jpg`,
    `${url}/panela-03.jpg`,
    `${url}/panela-04.jpg`
  ]

  const alt = 'Parafuso Panela'
  const head = [' ', '3.5', '4.2', '4.8']
  const body = [
    ['12', '✓', ' ', ' '],
    ['13', ' ', '✓', ' '],
    ['16', ' ', '✓', ' '],
    ['19', ' ', '✓', ' '],
    ['25', ' ', '✓', ' '],
    ['38', ' ', ' ', '✓']
  ]

  return (
    <Fragment>
      <div className='product-text'>
        {props.renderSlider(images, alt)}
        <h2>Parafuso Auto Brocante Panela</h2>
        <p>
          O Parafuso Brocante Panela GTR Parafusos, é Importado com a melhor
          qualidade de aço cementado. Nossos parafusos passam por um rigoroso
          controle de qualidade, com certificado, para garantir o melhor produto
          a nossos clientes.
        </p>
        <h3>Informações do Produto:</h3>
        Material: Aço Baixo Carbono<br/>
        Fenda: Philips<br/>
        Cabeça: Panela<br/>
        Tipo: Brocante<br/>
        Acabamento: Zincado Branco<br/>

        <h3>Medidas:</h3>
        {props.renderTable(head, body)}
      </div>
    </Fragment>
  )
}

export default Panela
