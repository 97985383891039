import React from 'react'
import Content from 'components/Layout/Content'
import Container from 'components/Layout/Container'

const products = [
  { image: '/images/produtos/highlights/chipboard.png', text: 'Chipboard', alt: 'chipboard' },
  { image: '/images/produtos/highlights/corredica.png', text: 'Corrediça', alt: 'corrediça' },
  { image: '/images/produtos/highlights/flangeado.png', text: 'Flangeado', alt: 'flangeado' },
]

const Products = () => {
  return (
    <Container>
      <Content className='home-products-section'>
        <h2 className='title text-blue'>ALGUNS DE NOSSOS PRODUTOS</h2>
      </Content>
      <Content className='home-products-list'>
        { products.map(item => (
          <div className='product-item' key={`product-${item.alt}`}>
            <img src={item.image} alt={item.alt} />
            <label className='product-name text-blue'>{item.text}</label>
          </div>
        ))}
      </Content>
    </Container>
  )
}

export default Products
