import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Container from 'components/Layout/Container'
import Content from 'components/Layout/Content'

const HeaderTop = props => {
  const className = props.showLogo ? 'header-slidedown' : 'header-slideup'

  return (
    <Fragment>
      <Container className={`header-top-fixed ${className}`}>
        <Content className='header-top-fixed-content'>
          <div className='header-top-bars only-mobile'>
          </div>
          <div className='header-top-logo'>
            <img src='/images/logo-pequeno.png' alt='logo GTR Fixação' />
          </div>
          <div className='only-desktop header-top-text'>
            Vendas: (11) 95865-7911 - (11) 95865-2426 - (11) 96839-4107 - (11) 98730-9765
          </div>
        </Content>
        
      </Container>

      <Container className='header-top'>
        <Content className='header-top-content only-desktop'>
          <div>
            Venda de Produtos de Fixação e Acessórios para Móveis
          </div>
          <div className="header-telephones">
            Vendas: (11) 95865-7911 - (11) 95865-2426 - (11) 96839-4107 - (11) 98730-9765
            <span>
              Financeiro: (11) 3333-3662
            </span>
          </div>
        </Content>

        <Content className='header-top-content only-mobile'>
          <div className="header-telephones">
            <div className="sales">
              Vendas: 
              <span>
                (11)95865-7911  - (11)95865-2426
              </span>
              <span>
                (11)96839-4107 - (11)98730-9765
              </span>
            </div>
            <span>
              Financeiro: (11) 3333-3662
            </span>
          </div>
        </Content>
      </Container>
    </Fragment>
  )
}

HeaderTop.propTypes = {
  showLogo: PropTypes.bool
}

HeaderTop.defaultProps = {
  showLogo: false
}

export default HeaderTop
