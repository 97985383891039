import React from 'react'
import PropTypes from 'prop-types'

const Content = props => {
  return (
    <div className={`content ${props.className}`}>
      {props.children}
    </div>
  )
}

Content.propTypes = {
  className: PropTypes.string
}

Content.defaultProps = {
  className: ''
}
export default Content
