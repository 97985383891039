import React, { Fragment } from 'react'

const DobradicaCurva = props => {
  const url = '/images/produtos/dobradica-curva'
  const images = [
    `${url}/dobradica-curva-01.jpg`,
    `${url}/dobradica-curva-02.jpg`,
    `${url}/dobradica-curva-03.jpg`
  ]

  const alt = 'Dobradiça Curva'

  return (
    <Fragment>
      <div className='product-text'>
        {props.renderSlider(images, alt)}
        <h2>Dobradiça Curva Comum 35mm</h2>
        <p>
          Com alta performance de abertura e fechamento, as dobradiças GTR
          Parafusos tem caneco de 35mm, utilizadas na fabricação de móveis com
          portas de madeiras. 
        </p>
        <h3>Informações do Produto:</h3>
        Material: Aço Niquelado<br/>
        Profundidade do Caneco: 11,3 MM<br/>
        Diâmetro do Caneco: 35 MM<br/>

        <h3>Modelo:</h3>
        Curva / 35 MM

        <p>
          <strong>*Dobradiça não contém amortecedor.</strong>
        </p>
      </div>
    </Fragment>
  )
}

export default DobradicaCurva
