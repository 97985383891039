import React from 'react'
import Container from 'components/Layout/Container'
import Content from 'components/Layout/Content'
import Address from 'components/Address'

const FooterInfo = () => {
  return (
    <Container className='footer-info'>
      <Content className='footer-info-content'>
        <div>
          <div className='company-name'>
            GTR Fixação
          </div>
          <div className='text'>
            Venda de Produtos de Fixação e Acessórios para Móveis
          </div>
        </div>

        <div>
          <Address />
        </div>
        
      </Content>
    </Container>
  )
}

export default FooterInfo
