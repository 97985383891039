import React, { Fragment } from 'react'

const Chipboard = props => {

  const url = '/images/produtos/chipboard'
  const images = [
    `${url}/chipboard-01.jpg`,
    `${url}/chipboard-02.jpg`,
    `${url}/chipboard-03.jpg`,
    `${url}/chipboard-04.jpg`,
    `${url}/chipboard-05.jpg`
  ]

  const head = [' ', '3.0',  '3.5', '4.0', '4.5', '5.0', '6.0']
  const body = [
//  [' ', '3.0',  3.5', '4.0', '4.5', '5.0', '6.0'],
    ['12',  ' ',  '✓',   '✓',   '✓',   ' ',   ' '  ],
    ['14',  '✓',  '✓',   '✓',   ' ',   ' ',   ' '  ],
    ['16',  '✓',  '✓',   '✓',   '✓',   ' ',   ' '  ],
    ['20',  ' ',  '✓',   '✓',   '✓',   ' ',   ' '  ],
    ['25',  '✓',  '✓',   '✓',   '✓',   ' ',   ' '  ],
    ['30',  '✓',  '✓',   '✓',   '✓',   '✓',   ' '  ],
    ['35',  ' ',  '✓',   '✓',   '✓',   '✓',   ' '  ],
    ['40',  ' ',  '✓',   '✓',   '✓',   '✓',   '✓'  ],
    ['45',  ' ',  ' ',   '✓',   '✓',   '✓',   ' '  ],
    ['50',  ' ',  ' ',   '✓',   '✓',   '✓',   '✓'  ],
    ['60',  ' ',  ' ',   ' ',   '✓',   '✓',   '✓'  ],
    ['70',  ' ',  ' ',   ' ',   ' ',   '✓',   '✓'  ],
    ['80',  ' ',  ' ',   ' ',   ' ',   ' ',   '✓'  ],
    ['90',  ' ',  ' ',   ' ',   ' ',   ' ',   '✓'  ],
    ['100', ' ',  ' ',   ' ',   ' ',   ' ',   '✓'  ]
  ]

  const alt = 'Chipboard'

  return (
    <Fragment>
      <div className='product-text'>
        {props.renderSlider(images, alt)}
        <h2>Parafuso Madeira Cabeça Chata Philips Bicromatizado</h2>
        <p>
          O Parafuso Chipboard GTR Parafusos, é Importado com a melhor qualidade
          de aço cementado. Nossos parafusos passam por um rigoroso controle de
          qualidade, com certificado, para garantir o melhor produto a nossos
          clientes.
        </p>

        <h3>Informações do Produto:</h3>
        Material: Aço Baixo Carbono<br />
        Fenda: Philips<br />
        Cabeça: Chata<br />
        Chipboard<br />
        Acabamento: Bicromatizado<br />

        <h3>Dispomos das medidas:</h3>
        {props.renderTable(head, body)}
      </div>
    </Fragment>
  )
}

export default Chipboard
