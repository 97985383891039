import React, { Fragment } from 'react'

const FlangeadoBrocaSerrilha = props => {
  const url = '/images/produtos/flangeado-broca-serrilha'
  const images = [
    `${url}/flangeado-broca-serrilha-01.jpg`,
    `${url}/flangeado-broca-serrilha-02.jpg`,
    `${url}/flangeado-broca-serrilha-03.jpg`
  ]

  const alt = 'Flangeado Broca Serrilha'

  return (
    <Fragment>
      <div className='product-text'>
        {props.renderSlider(images, alt)}
        <h2>Parafuso Flangeado Ponta Broca C/ Serrilha</h2>
        <p>
          O Parafuso Flangeado Brocante Serrilhado GTR Parafusos, é Importado com
          a melhor qualidade de aço cementado. Nossos parafusos passam por um
          rigoroso controle de qualidade, com certificado, para garantir o melhor
          produto a nossos clientes.
        </p>
        <h3>Informações do Produto:</h3>
        Material: Aço Baixo Carbono<br/>
        Fenda: Philips<br/>
        Cabeça: Flangeada<br/>
        Tipo: Broca C/ Serrilha<br/>
        Acabamento: Zincado Branco<br/>

        <h3>Medida:</h3>
        4.2*13<br/>
        4.2*16<br/>
        4.2*19<br/>
        4.2*25<br/>
      </div>
    </Fragment>
  )
}

export default FlangeadoBrocaSerrilha
