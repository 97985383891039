import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import HeaderTop from './HeaderTop'
import MenuTop from 'components/Menu/MenuTop'
import MenuSlide from 'components/Menu/MenuSlide'
import Content from 'components/Layout/Content'
import Address from 'components/Address'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showTopLogo: false
    }

    this.line = React.createRef()
  }
  
  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }
  
  handleScroll = () => {
    if (!this.line.current) {
      return
    }

    const top = this.line.current.getBoundingClientRect().top
    if (top < -80) {
      return this.setState({ showTopLogo: true })
    }

    this.setState({ showTopLogo: false })
  }

  render () {
    return (
      <Fragment>
        <header className='header'>

          <MenuSlide page={this.props.page} />
          <HeaderTop showLogo={this.state.showTopLogo} />
          <div className='header-line' ref={this.line}></div>

          <Content className='header-nav'>
            <img src='/images/logo-gtr-fixacao.png' alt='Logo GTR Fixação' className='header-logo' />
            <div className='logo-slogan only-mobile'>
              Venda de Produtos de Fixação<br/> e Acessórios para Móveis
            </div>
            <MenuTop page={this.props.page} />
            <Address />
          </Content>
        </header>
      </Fragment>
    )
  }
}

Header.propTypes = {
  page: PropTypes.string.isRequired
}

export default Header
