import React, { Fragment } from 'react'

const CorredicaComAmortecedor = props => {

  const url = '/images/produtos/corredica-com-amortecedor'
  const images = [
    `${url}/corredica-com-amortecedor-5.jpg`,
    `${url}/corredica-com-amortecedor-2.jpg`,
    `${url}/corredica-com-amortecedor-1.jpg`,
    `${url}/corredica-com-amortecedor-3.jpg`,
    `${url}/corredica-com-amortecedor-4.jpg`,
    `${url}/corredica-com-amortecedor-6.jpg`,
  ]

  const alt = 'Corrediça Telescópica Larga Com Amortecedor - 45mm'

  return (
    <Fragment>
      <div className='product-text'>
        {props.renderSlider(images, alt)}
        <h2>Corrediça Telescópica Larga Com Amortecedor - 45mm</h2>
        <p>
          As corrediças telescópicas GTR Parafusos, são importadas em aço zincado,
          cobertura protetora contra oxidação, com sistema de fixação lateral, possui
          trava que permite a retirada completa da gaveta sempre que necessário.
          Possui amortecimento que proporciona maior conforto evitando impacto nas
          gavetas e modernidade para seu móvel.
        </p>
        <p>
          <strong>
            *Corrediça Telescópica Larga - 45mm de largura, suporta gavetas de até 55 kg
          </strong>
        </p>
        <h3>Tamanhos:</h3>
        TRILHO TELESCÓPICO LARGA COM AMORTECEDOR 400 MM<br />
        TRILHO TELESCÓPICO LARGA COM AMORTECEDOR 450 MM<br />
        TRILHO TELESCÓPICO LARGA COM AMORTECEDOR 500 MM<br />
      </div>
    </Fragment>
  )
}

export default CorredicaComAmortecedor 
