import React, { Component, Fragment } from 'react'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import Content from 'components/Layout/Content'

class ContatoPage extends Component {

  render () {
    return (
      <Fragment>
        <Header page='contato' />
        <main className='contato'>
          <div className='contato-banner text-border-blue'>
            PRODUTOS PARA FIXAÇÃO E ACESSÓRIOS PARA MÓVEIS
          </div>

          <Content className='contato-info'>
            <div>
              <h4 className='title'>Endereço</h4>
              <address>
                <a
                  href='https://www.waze.com/ul?ll=-23.459701%2C-46.474725&navigate=yes&zoom=17'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Av. Patos, 420 – Cidade Industrial Satélite<br/>
                  Guarulhos / SP – CEP 07222-010
                </a>
              </address>
            </div>
            <div>
              <h4 className='title'>Atendimento</h4>
              De segunda à sexta-feira:<br/>
              Das 08:00 as 17:00
            </div>
          </Content>

          <Content className='contato-maps'>
            <iframe
              title='Maps'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14640.528442277397!2d-46.476072906963395!3d-23.45569865821956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce61d38f9f87ef%3A0x66af6751e6355886!2sAv.%20Patos%2C%20420%20-%20Cidade%20Industrial%20Sat%C3%A9lite%20de%20S%C3%A3o%20Paulo%2C%20Guarulhos%20-%20SP%2C%2007222-010!5e0!3m2!1spt-BR!2sbr!4v1566770435009!5m2!1spt-BR!2sbr"
              width="100%" height="600" frameBorder="0" allowFullScreen=""></iframe>
          </Content>
        </main>
        <Footer />
      </Fragment>
    )
  }
}

export default ContatoPage
