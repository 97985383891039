import React from 'react'
import FooterInfo from './FooterInfo'
import FooterRights from './FooterRights'

const Footer = props => {
  return (
    <footer>
      <FooterInfo />
      <FooterRights />
    </footer>
  )
}

export default Footer
