import React, { Fragment } from 'react'

const TrombetaGN25 = props => {
  const url = '/images/produtos/gn25'
  const images = [
    `${url}/gn25-01.jpg`,
    `${url}/gn25-02.jpg`,
    `${url}/gn25-03.jpg`
  ]

  const alt = 'Parafuso Trombeta GN25'

  return (
    <Fragment>
      <div className='product-text'>
        {props.renderSlider(images, alt)}
        <h2>Parafuso Trombeta Ponta Agulha – GN</h2>
        <p>
          O Parafuso GN 25 GTR Parafusos, é Importado com a melhor qualidade de
          aço cementado. Nossos parafusos passam por um rigoroso controle de
          qualidade, com certificado, para garantir o melhor produto a nossos clientes.
        </p>
        <h3>Informações do Produto:</h3>
        Material: Aço Baixo Carbono<br/>
        Fenda: Philips<br/>
        Cabeça: Trombeta<br/>
        Tipo: Agulha<br/>
        Acabamento: Fosfatizado<br/>

        <h3>Medida:</h3>
        3.5x25
      </div>
    </Fragment>
  )
}

export default TrombetaGN25
