import React from 'react'
import Slider from 'components/Slider/Slider'

const Banner = () => {

  const Banner1 = () => (
    <div className='banner banner1'>
      <h1 className='banner-title text-border-white'>
        PRODUTOS DE FIXAÇÃO E<br/>
        ACESSÓRIOS PARA MÓVEIS
      </h1>
      <div className='banner-description text-border-blue'>
        Produtos importados
        de altíssima qualidade.
      </div>
    </div>
  )
  
  const Banner2 = () => (
    <div className='banner banner2'>
      <h1 className='banner-title text-border-white'>
        IMPORTAÇÃO DIRETA
      </h1>
      <div className='banner-description text-border-blue'>
        Importamos diretamente do fornecedor, 
        garantindo qualidade e formas de 
        pagamento diferenciados.
      </div>
    </div>
  )

  const Banner3 = () => (
    <div className='banner banner3'>
      <h1 className='banner-title text-border-white'>
        ACESSÓRIOS PARA MÓVEIS
      </h1>
      <div className='banner-description text-border-blue'>
        Conheça a nossa linha de acessórios para móveis. Qualidade garantida.
      </div>
      <img src='/images/home/corredicas-banner.png' alt='corrediças' className='corredicas' />
      <img src='/images/home/dobradicas-banner.png' alt='dobradiças' className='dobradicas' />
    </div>
  )

  const Banner4 = () => (
    <div className='banner banner4'>
      <h1 className='banner-title text-border-white'>
        PRODUTOS DE FIXAÇÃO
      </h1>
      <div className='banner-description text-border-blue'>
        Produtos de qualidade internacional.
      </div>
      <img src='/images/home/chipboard-banner.png' alt='chipboard' className='chipboard' />
      <img src='/images/home/trombeta-banner.png' alt='Trombeta' className='trombeta' />
    </div>
  )
  
  return (
    <Slider
      className='banner-container'
      settings={{initialSlideHeight: 780, heightMode: 'max'}}
    >
      <Banner1 />
      <Banner2 />
      <Banner3 />
      <Banner4 />
    </Slider>
  )
}

export default Banner
