import React, { Component, Fragment } from 'react'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import Banner from './components/Banner'
import Boxes from './components/Boxes'
import Strip from './components/Strip'
import Products from './components/Products'

class HomePage extends Component {
  render () {
    return (
      <Fragment>
        <Header page='home' />
        <main>
          <Banner />
          <Boxes />
          <Strip />
          <Products />
        </main>
        <Footer />
      </Fragment>
    )
  }
}

export default HomePage
