import React, { Fragment } from 'react'

const RodizioComFreio = props => {

  const url = '/images/produtos/rodizio-com-freio'
  const images = [
    `${url}/rodizio-com-freio-3.jpg`,
    `${url}/rodizio-com-freio-2.jpg`,
    `${url}/rodizio-com-freio-1.jpg`,
    `${url}/rodizio-com-freio-4.jpg`
  ]

  const alt = 'Rodízio com Freio'

  return (
    <Fragment>
      <div className='product-text'>
        {props.renderSlider(images, alt)}
        <h2>Rodízio Giratório em Gel com Freio</h2>
        <p>
          Os rodízios giratórios são utilizados para melhorar a movimentação de
          equipamentos. Possui eixo giratório que permite movimentos em 360º dos
          rodízios.<br/>
          Indicado para movimentação de móveis, bancadas, carrinhos, máquinas e
          equipamentos em pisos que não podem ficar marcados pelo atrito do rodízio.<br/>
          Proporciona durabilidade e resistência, além de ser silencioso e não marcar o piso.
        </p>
        <h3>Informações do produto:</h3>
        Material: Aço e poliuretano
        <h3>Tamanhos:</h3>
        35 MM<br />
        50 MM<br />
        75 MM<br />
      </div>
    </Fragment>
  )
}

export default RodizioComFreio
